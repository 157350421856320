<template>
  <div>
    <header-slot />
    <b-nav card-header pills class="m-0">
      <b-nav-item
        :to="{ name: 'ce-dispute-results-in-process' }"
        exact
        :exact-active-class="status === 0 ? 'active' : ''"
        :link-classes="['px-3', bgTabsNavs]"
        >IN PROCESS
      </b-nav-item>
      <b-nav-item
        :to="{ name: 'ce-dispute-results-completed' }"
        exact
        :exact-active-class="status === 1 ? 'active' : ''"
        :link-classes="['px-3', bgTabsNavs]"
        >COMPLETED
        <!-- <span v-if="G_COUNTER_DISPUTE > 0" class="ml-1">
          <feather-icon
            icon
            :badge="G_COUNTER_DISPUTE > 99 ? '99+' : G_COUNTER_DISPUTE"
            badge-classes="badge-danger badge-glow"
          />
        </span> -->
      </b-nav-item>
    </b-nav>
    <router-view></router-view>
  </div>
</template>
<script>
//components
import { mapGetters } from "vuex";
export default {
  components: {
  },
  computed: {
    status() {
      return this.$route.meta.status;
    },

    ...mapGetters({
      G_COUNTER_DISPUTE: "BoostCreditDisputeResult/G_COUNTER_DISPUTE",
    }),
  },
};
</script>
